import store from '@src/redux/store';
import { SET_CUSTOMER, SET_PRODUCT } from '@src/redux/contract/actions';
import { SelectListItem } from '@src/interfaces/RootState';
import { change } from 'redux-form';
import { Product } from '../interfaces/Product';

// TODO implement the appropriate data
export class ProductModel implements Product {
    brand: string;
    group: string;
    model: string;
    price: number | string;
    serial: number | string;
    warranty: string;
    warrantyPrice: number;
    warrantySellingPrice: number;
    comment: string;
    brandItem: SelectListItem;
    groupItem: SelectListItem;
    warrantyItem: SelectListItem;

    constructor(product: Product) {
        console.log('PRODUCT MODEL', product);
        this.brand = product.brand;
        this.group = product.group;
        this.model = product.model;
        this.price = product.price;
        this.serial = product.serial;
        this.warranty = product.warranty;
        this.warrantyPrice = product.warrantyPrice;
        this.warrantySellingPrice = product.warrantySellingPrice;
        this.comment = product.comment;
        this.brandItem = store.getState()?.unit?.productBrandList?.find((item: SelectListItem) => item.value === product.brand);
        this.groupItem = store.getState()?.unit?.productGroupList?.find((item: SelectListItem) => item.value === product.group);
        this.warrantyItem = store.getState()?.unit?.warrantyList?.find((item: SelectListItem) => item.value === product.warranty);
    }
    public summarize() {
        return {
            brand: this.brandItem?.label,
            group: this.groupItem?.label,
            model: this.model,
            price: this.price,
            serial: this.serial,
            warranty: this.warrantyItem?.label,
            warranty_price: this.warrantyPrice,
            warranty_selling_price: this.warrantySellingPrice,
            comment: this.comment,
        };
    }
    public static populateForm() {
        // TODO Rewrite variable names
        // ! Why?
        const productModel = store.getState().contract.productModel;
        if (productModel) {
            Object.entries(productModel).forEach((element) => {
                store.dispatch(change('productForm', element[0], element[1]));
            });
        }
    }
    public static storeProduct(instance) {
        console.log('instance', instance);
        store.dispatch({ type: SET_PRODUCT, val: instance });
    }
}
