import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
    components: {
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    background: 'red',
                    input: {
                        '&-webkit-autofill': {
                            background: 'red', // Háttérszín, amikor az input ki van töltve
                        },
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    input: {
                        '&-animated': {
                            background: 'red', // Háttérszín, amikor az input ki van töltve
                        },
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    input: {
                        background: 'rgba(255,255,255,.5)',
                    },
                    '&.Mui-focused': {
                        background: '#DDDDDD', // Háttérszín, amikor az input ki van töltve
                    },
                    '&-webkit-autofill': {
                        background: 'red !important', // Háttérszín, amikor az input ki van töltve
                    },
                },
                // '&.Mui-focused': {
                //     input: {
                //         background: 'blue', // Háttérszín, amikor az input ki van töltve
                //     },
                // },
            },
        },
        // MuiInputLabel: {
        //     variants: [
        //         {
        //             props: { shrink: true },

        //             style: {
        //                 background: '#E8F0FE',
        //             },
        //         },
        //     ],
        // },

        // MuiTextField: {
        //     variants: [
        //         {
        //             props: { variant: 'outlined' },
        //             style: {
        //                 background: 'rgba(255,255,255,.5)',
        //             },
        //         },
        //     ],
        // },
        MuiSelect: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        background: 'rgba(255,255,255,.5)',
                    },
                },
            ],
        },
    },
    palette: {
        primary: {
            main: '#FF7900',
        },
        secondary: {
            main: '#32C832',
        },
        text: {
            primary: '#fffff',
        },
        background: {
            default: '#527EDB',
            // paper: '#2784C6',
        },
    },
});
