import { SET_CONTRACT_DATA } from '@src/redux/contract/actions';
import { ContractData } from '../interfaces/ContractData';
import store from '@src/redux/store';

export class ContractDataModel implements ContractData {
    contractNumber: string;
    constructor(contract: ContractData) {
        this.contractNumber = contract.contractNumber;
    }
    public static storeContract(instance) {
        store.dispatch({ type: SET_CONTRACT_DATA, val: instance });
    }
}
