import store from '@src/redux/store';
import { SET_CUSTOMER, SET_PARTNER } from '@src/redux/contract/actions';
import dayjs, { Dayjs } from 'dayjs';
import { SelectListItem } from '@src/interfaces/RootState';
import { change } from 'redux-form';
import { Partner } from '../interfaces/Partner';
import dateFormatter from '@src/components/Utility/util';

export class PartnerModel implements Partner {
    partner: string;
    shop: string;
    employee: string;
    employeeName: string;
    newEmployee: string;
    sellingDate: any;
    deliveryDate: any;
    email: string;
    receiptNumber: string;
    partnerItem: SelectListItem;
    shopItem: SelectListItem;
    employeeItem: SelectListItem;
    storePartner: (instance: Partner) => void;
    constructor(partner: Partner) {
        this.partner = partner.partner;
        this.shop = partner.shop;
        this.email = partner.email;
        this.employee = partner.employee;
        this.employeeName = partner.employeeName;
        this.sellingDate = dayjs(partner.sellingDate).toDate();
        this.deliveryDate = dayjs(partner.deliveryDate).toDate();
        this.receiptNumber = partner.receiptNumber;
        this.partnerItem = store.getState()?.unit?.partnerList?.find((item: SelectListItem) => item.value === partner.partner);
        this.shopItem = store.getState()?.unit?.shopList?.find((item: SelectListItem) => item.value === partner.shop);
        this.employeeItem = store.getState()?.unit?.employeeList?.find((item: SelectListItem) => item.value === partner.employee);
    }
    public summarize() {
        return {
            partner: this.partnerItem?.label,
            shop: this.shopItem?.label,
            employee: this.employeeItem?.label,
            selling_date: dayjs(this.sellingDate).format('L'),
            delivery_date: dayjs(this.deliveryDate).format('L'),
            // receipt_number: this.receiptNumber,
        };
    }
    public static populateForm() {
        const partnerModel = store.getState().contract.partnerModel;
        if (partnerModel) {
            Object.entries(partnerModel).forEach((element) => {
                if (element[0].includes('Item')) return;
                store.dispatch(change('partnerForm', element[0], element[1]));
            });
        }
    }
    public static storePartner(instance) {
        store.dispatch({ type: SET_PARTNER, val: instance });
    }
}
