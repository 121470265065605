import { takeLatest } from 'redux-saga/effects';
import {
    getCountryList,
    getBankCountryList,
    getEmployeeList,
    getLanguageList,
    getPartnerList,
    getProductBrandList,
    getProductGroupList,
    getShopList,
    getWarrantyList,
    // getWarrantyPriceList,
    GET_COUNTRY_LIST,
    GET_BANK_COUNTRY_LIST,
    GET_EMPLOYEE_LIST,
    GET_LANGUAGE_LIST,
    GET_PARTNER_LIST,
    GET_PRODUCT_BRAND_LIST,
    GET_PRODUCT_GROUP_LIST,
    GET_SHOP_LIST,
    GET_WARRANTY_LIST,
    // GET_WARRANTY_PRICE_LIST,
    GET_SHOP_DETAILS,
    getShopDetails,
} from './actions';

export default [
    takeLatest(GET_BANK_COUNTRY_LIST, getBankCountryList),
    takeLatest(GET_COUNTRY_LIST, getCountryList),
    takeLatest(GET_LANGUAGE_LIST, getLanguageList),
    takeLatest(GET_PARTNER_LIST, getPartnerList),
    takeLatest(GET_SHOP_LIST, getShopList),
    takeLatest(GET_EMPLOYEE_LIST, getEmployeeList),
    takeLatest(GET_PRODUCT_GROUP_LIST, getProductGroupList),
    takeLatest(GET_PRODUCT_BRAND_LIST, getProductBrandList),
    takeLatest(GET_WARRANTY_LIST, getWarrantyList),
    // takeLatest(GET_WARRANTY_PRICE_LIST, getWarrantyPriceList),
    takeLatest(GET_SHOP_DETAILS, getShopDetails),
];
