// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// const dateFormatter = (target) => {
// 	return (
// 		`${
// 			target.getDate().toString().length === 1
// 				? `0${target.getDate()}`
// 				: target.getDate()
// 		}/` +
// 		`${
// 			`${target.getMonth() + 1}`.toString().length === 1
// 				? `0${target.getMonth() + 1}`
// 				: `${target.getMonth() + 1}`
// 		}/` +
// 		`${target.getFullYear()}`
// 	);
// };

// export default dateFormatter;
const language = localStorage.getItem('language');
import i18next from 'i18next';
import { addValidator } from 'redux-form-validators';

export const ibanValidator = addValidator({
    defaultMessage: 'IBAN format: BEXXXXXXXXXXXXXX',
    validator: function (options, value, allValues) {
        console.log('file: util.ts:21 ~ i18next', i18next);

        return (options.lowerCase ? /^BE[0-9]{14}$/i : /^be[0-9]{14}$/i).test(value);
    },
});

export const ibanValidatorIsReal = addValidator({
    defaultMessage: 'IBAN not valid!',
    validator: function (options, value, allValues) {
        // let ibanNumber = 10;
        // eslint-disable-next-line prefer-const
        // for (const val in value) {
        // 	ibanNumber = value[val];
        // }
        const ibanArray: any[] = [...value];
        const ibanValidateNumber = '' + ibanArray[2] + ibanArray[3];
        const ibanArrayNew: any[] = [];
        for (let i = 4; i < ibanArray.length; i++) {
            ibanArrayNew.push(ibanArray[i]);
        }
        ibanArrayNew.push('11');
        ibanArrayNew.push('14');
        ibanArrayNew.push(ibanValidateNumber);
        const ibanNew = ibanArrayNew.join('');
        const firstPart: any = ibanNew.slice(0, 9);
        const secondPart: any = ibanNew.slice(9, 16);
        const thirdPart: any = ibanNew.slice(16, 19);
        const mod1 = (firstPart * 1) % 97;
        const secondParts: any = '' + mod1 + secondPart;
        const mod2 = (secondParts * 1) % 97;
        const thirdParts: any = '' + mod2 + thirdPart;
        const finalScore: any = thirdParts % 97;
        if (finalScore === 1) {
            return true;
        } else {
            return false;
        }
    },
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const dateFormatter = (target) => {
    // console.log('TARGET', target);
    return (
        `${target.getFullYear()}-${`${target.getMonth() + 1}`.toString().length === 1 ? `0${target.getMonth() + 1}` : `${target.getMonth() + 1}`}-` +
        `${target.getDate().toString().length === 1 ? `0${target.getDate()}` : target.getDate()}`
    );
};

export default dateFormatter;

export const dateFormatterBe = (target: any): string => {
    return (
        `${target?.getDate().toString().length === 1 ? `0${target?.getDate()}` : target?.getDate()}` +
        `-${`${target?.getMonth() + 1}`.toString().length === 1 ? `0${target?.getMonth() + 1}` : `${target?.getMonth() + 1}`}-${target?.getFullYear()}`
    );
};

export const isLuhnNumber = (code) => {
    const len = code?.length;
    const parity = len % 2;
    let sum = 0;
    for (let i = len - 1; i >= 0; i--) {
        let d = parseInt(code?.charAt(i));
        if (i % 2 == parity) {
            d *= 2;
        }
        if (d > 9) {
            d -= 9;
        }
        sum += d;
    }
    return sum % 10 === 0;
};
