import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import * as React from 'react';
dayjs.extend(localizedFormat);
interface DatePickerProps {
    onChange: (a: Date) => void;
    placeholder?: string;
    minDate?: Date;
    maxDate?: Date;
    input?: any;
    disabled?: boolean;
    value?: any;
    popperPlacement?: string;
    meta?: any;
    label?: string;
    variant?: any;
}

const MaterialMobileDatePicker = ({ onChange, input, id, label, placeholder, variant, minDate, maxDate, disabled, defaultValue, ...custom }) => {
    // console.log('file: MobileDatePicker.tsx:27 ~ MaterialMobileDatePicker ~ defaultValue:', defaultValue);

    const [dateValue, setValue] = React.useState<any>(defaultValue ?? dayjs());

    // console.log('file: MobileDatePicker.tsx:30 ~ MaterialMobileDatePicker ~ dateValue:', dateValue);

    const handleChange = (newValue: Dayjs | null) => {
        setValue(newValue);
        onChange(newValue);
    };

    React.useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <MobileDatePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    label={placeholder}
                    disabled={disabled}
                    inputFormat="DD/MM/YYYY"
                    value={dateValue}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} id={id} />}
                />
            </Stack>
        </LocalizationProvider>
    );
};

const renderDatePickerField = ({ input, meta, minDate, maxDate, placeholder, disabled, id, variant, ...props }) => {
    // console.log('meta', meta);
    return (
        <div className={`datepicker ${meta.touched && meta.error && 'error'}`}>
            <MaterialMobileDatePicker
                id={id}
                className="material__mobile-date-picker"
                {...input}
                defaultValue={props?.defaultValue}
                minDate={minDate}
                maxDate={maxDate}
                placeholder={placeholder}
                disabled={disabled}
            />
            {/* <DatePickerField {...input} minDate={minDate} maxDate={maxDate} placeholder={placeholder} /> */}
            {meta.touched && meta.error && (
                <span className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">{meta.error}</span>
            )}
        </div>
    );
};
export default renderDatePickerField;
