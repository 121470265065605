import { LoginResponse } from './../../api/interfaces/Login';
import ContractService, { smsAuthType } from '@src/api/services/contractService';
import RootState, { Contract } from '@src/interfaces/RootState';
import dayjs, { Dayjs } from 'dayjs';
import { put, select } from 'redux-saga/effects';
import { SET_ERROR_OBJECT } from '../error/actions';
import { SET_LOADING } from './../ui/actions';
import { CustomerModel } from '@src/api/models/CustomerModel';
import { Customer } from '@src/api/interfaces/Customer';
import { AccountHolderModel } from '@src/api/models/AccountHolderModel';
import { AccountHolder } from '@src/api/interfaces/AccountHolder';
import UnitService from '@src/api/services/UnitService';
import { BankAccountModel } from '@src/api/models/BankAccountModel';
import { BankAccount } from '@src/api/interfaces/BankAccount';
import { PartnerModel } from '@src/api/models/PartnerModel';
import { ProductModel } from '@src/api/models/ProductModel';
import { change } from 'redux-form';
import { GET_PARTNER_LIST, GET_PRODUCT_BRAND_LIST, GET_PRODUCT_GROUP_LIST, GET_SHOP_LIST, GET_WARRANTY_LIST } from '../unit/actions';
import { ContractDataModel } from '@src/api/models/ContractDataModel';
import { ContractData } from '@src/api/interfaces/ContractData';
import { setNotification } from '@src/components/ui/Notifications';

/** Saga Actions */
export const SAVE_NEW_CONTRACT = 'SAVE_NEW_CONTRACT';
export const SAVE_MODIFIED_CONTRACT = 'SAVE_MODIFIED_CONTRACT';
export const VERIFY_SMS_CODE = 'VERIFY_SMS_CODE';
export const SEND_MAIL = 'SEND_MAIL';
export const SEND_SMS = 'SEND_SMS';
export const GET_PROJECT_PROPERTIES = 'GET_PROJECT_PROPERTIES';
export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_DIARIES = 'GET_DIARIES';
export const GET_TERMS_CONDITIONS = 'GET_TERMS_CONDITIONS';
export const GET_DEVICE = 'GET_DEVICE';
export const GET_BANK_LIST = 'GET_BANK_LIST';

/** Reducer Actions */
export const SET_PROJECT_PROPERTIES = 'SET_PROJECT_PROPERTIES';
export const SET_DIARIES = 'SET_DIARIES';
export const SET_CONTRACT_SAVED = 'SET_CONTRACT_SAVED';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PARTNER = 'SET_PARTNER';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_CONTRACT_DATA = 'SET_CONTRACT_DATA';
export const SET_ACCOUNT_HOLDER = 'SET_ACCOUNT_HOLDER';
export const SET_BANK_ACCOUNT = 'SET_BANK_ACCOUNT';
export const SET_CONTRACT_NUMBER = 'SET_CONTRACT_NUMBER';
export const RESET_CONTRACT_STORE = 'RESET_CONTRACT_STORE';
export const SET_SMS_ID = 'SET_SMS_ID';
export const SEARCH_CONTRACT = 'SEARCH_CONTRACT';
export const SEARCH_CONTRACT_FOR_LIST = 'SEARCH_CONTRACT_FOR_LIST';
export const SET_CONTRACT_LIST = 'SET_CONTRACT_LIST';
export const SET_CONTRACT_LIST_LINKS = 'SET_CONTRACT_LIST_LINKS';
export const SET_CONTRACT_LIST_PAGEINFO = 'SET_CONTRACT_LIST_PAGEINFO';

export function* saveModifiedContract(action) {
    console.log('function*saveModifiedContract ~ action:', action);

    yield put({ type: SET_LOADING, val: true });
    const getModels = yield select((state: RootState) => state?.contract);
    // const contractNu;
    const payload = {
        accountHolderEmail: getModels?.accountHolderModel?.emailAh,
        accountHolderLanguageId: getModels?.accountHolderModel?.languageAh,
        accountHolderName: getModels?.accountHolderModel?.customerNameAh,
        accountHolderPhone: getModels?.accountHolderModel?.phoneNumberAh,
        accountHolderAddress: {
            countryCode: getModels?.accountHolderModel?.countryAh,
            city: getModels?.accountHolderModel?.cityAh,
            postcode: getModels?.accountHolderModel?.postCodeAh,
            street: getModels?.accountHolderModel?.streetAh,
            streetNumber: getModels?.accountHolderModel?.houseNumberAh,
            // postbox: getFormData?.boxAh,
        },
        articlePrice: getModels?.productModel?.price,
        articleType: getModels?.productModel?.model,
        brandId: getModels?.productModel?.brand,
        comment: getModels?.productModel?.comment,
        contractEmail: getModels?.customerModel?.email,
        contractAddress: {
            countryCode: getModels?.customerModel?.country,
            city: getModels?.customerModel?.city,
            postcode: getModels?.customerModel?.postCode,
            street: getModels?.customerModel?.street,
            streetNumber: getModels?.customerModel?.houseNumber,
            // postbox: getFormData?.box,
        },
        contractedPhoneNumber: getModels?.customerModel?.phoneNumber,
        contractNumber: action.val, // ! from action val
        countryId: getModels?.customerModel?.country,
        partnerId: getModels?.partnerModel?.partner,
        employeeId: getModels?.partnerModel?.employee,
        groupId: getModels?.productModel?.group,
        ibanNumber: getModels?.bankAccountModel?.iban,
        languageId: getModels?.customerModel?.language,
        lastName: getModels?.customerModel?.customerName,
        phoneNumber: getModels?.customerModel?.phoneNumber,
        receiptNumber: getModels?.partnerModel?.receiptNumber,
        // title: getFormData?.title,
        taxIdentificationNumber: getModels?.customerModel?.vatNumber,
        salesDate: dayjs(new Date(getModels?.partnerModel?.sellingDate)).format('YYYY-MM-DD'),
        serialNumber: getModels?.productModel?.serial,
        shipDate: dayjs(new Date(getModels?.partnerModel?.deliveryDate)).format('YYYY-MM-DD'),
        shopId: getModels?.partnerModel?.shop,
        warrantySalesDate: dayjs(new Date(getModels?.partnerModel?.deliveryDate)).format('YYYY-MM-DD'),
        warrantySalesPrice: getModels?.productModel?.warrantySellingPrice,
        warrantyTypeId: getModels?.productModel?.warranty,
    };
    try {
        const response = yield ContractService.saveModifiedContract(payload);
        if (response.status === 200) {
            yield put({ type: SET_CONTRACT_NUMBER, val: action.val });
        } else {
            if (response.status === 400 && response?.data?.message.includes('email_is_black_listed')) {
                yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 413 } });
            } else if (response.status === 400 && response?.data?.message.includes('wrong_email_syntax')) {
                yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 414 } });
            } else if (response.status === 400 && response?.data?.message.includes('host_does_not_exist')) {
                yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 415 } });
            } else if (response.status === 400 && response?.data?.message.includes('invalid_mx_record')) {
                yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 416 } });
            } else {
                yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
            }
        }
    } catch (error) {
        console.log('function*saveModifiedContract ~ error:', error);

        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: error?.response?.status } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* getContract(action) {
    yield put({ type: SET_LOADING, val: true });
    const requestBody = action.val;
    try {
        // PartnerId is neccessary for product GROUP and BRAND
        yield put(change('partnerForm', 'partner', requestBody.partnerId));
        // ! GET UNIT data
        yield put({ type: GET_PRODUCT_GROUP_LIST });
        yield put({ type: GET_PRODUCT_BRAND_LIST });
        yield put({ type: GET_SHOP_LIST });
        yield put({ type: GET_PARTNER_LIST });
        yield put({ type: GET_WARRANTY_LIST });
        yield put({ type: GET_ACCOUNT });

        const contractResponse = yield ContractService.searchContract(requestBody);
        const accountResponse = yield ContractService.getAccount(requestBody.partnerId, requestBody.contractNumber);
        const deviceResponse = yield UnitService.getDevice(contractResponse?.data?._embedded?.contracts[0].deviceId);

        console.log('DEVICERESPONSE', deviceResponse);
        const payLoad = {
            bank_code: accountResponse.data.bankCode,
        };
        const bankResponse = yield UnitService.bankList(payLoad);

        /** Extend bank response
         * ! In api layer it doesn't work to edit response
         */
        const bank = bankResponse?.data[0];
        bank.bankName = bank.bank_name;
        bank.bankCountry = bank.country_id;
        bank.iban = accountResponse.data.ibanNumber;
        /**
         * ! ---------------------------------------------
         */

        // ! Create instance
        const ContractInstance = new ContractDataModel(contractResponse?.data?._embedded?.contracts[0] as ContractData);
        const customerInstance = new CustomerModel(contractResponse?.data?._embedded?.contracts[0] as Customer);
        const accountHolderInstance = new AccountHolderModel(accountResponse?.data as AccountHolder);
        const bankInstance = new BankAccountModel(bank as BankAccount);
        const partnerInstance = new PartnerModel(contractResponse?.data?._embedded?.contracts[0] as PartnerModel);
        const deviceInstance = new ProductModel(deviceResponse?.data as ProductModel);

        yield put({ type: GET_WARRANTY_LIST });

        // ! Store instance
        ContractDataModel.storeContract(ContractInstance);
        CustomerModel.storeCustomer(customerInstance);
        AccountHolderModel.storeAccountHolder(accountHolderInstance);
        BankAccountModel.storeBankAccount(bankInstance);
        PartnerModel.storePartner(partnerInstance);
        console.log('DEVICEINSTANCE', deviceInstance);
        ProductModel.storeProduct(deviceInstance);

        // ! Populate forms
        CustomerModel.populateForm();
        AccountHolderModel.populateForm();
        BankAccountModel.populateForm();
        // PartnerModel.populateForm();
        ProductModel.populateForm();
        // CustomerModel.populateForm();
        yield put(change('productForm', 'warranty', contractResponse?.data?._embedded?.contracts[0].ewType.id));
        yield put(change('productForm', 'warranty', contractResponse?.data?._embedded?.contracts[0].ewType.id));
    } catch (error) {
        console.log('function*getContract ~ error:', error);
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* searchContract(action) {
    yield put({ type: SET_LOADING, val: true });

    const payload = {
        page: action?.val?.page,
        size: action?.val?.size,
        contractNumber: action?.val?.contractNumber,
        serialNumber: action?.val?.serialNumber,
        customerName: action?.val?.customerName,
        employeeName: action?.val?.employeeName,
        contractRegistrationDateFrom: action?.val?.contractRegistrationDateFrom,
        contractRegistrationDateTo: action?.val?.contractRegistrationDateTo,
    };

    try {
        const response = yield ContractService.searchContract(payload);

        yield put({ type: SET_CONTRACT_LIST, val: response?.data?._embedded?.contracts });
        yield put({ type: SET_CONTRACT_LIST_LINKS, val: response?.data?._links });
        yield put({ type: SET_CONTRACT_LIST_PAGEINFO, val: response?.data?.page });
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: error?.response?.status } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* searchContractForList(action) {
    yield put({ type: SET_LOADING, val: true });

    const payload = {
        page: action?.val?.page,
        size: action?.val?.size,
        contractNumber: action?.val?.contractNumber,
        serialNumber: action?.val?.serialNumber,
        customerName: action?.val?.customerName,
        employeeName: action?.val?.employeeName,
        contractRegistrationDateFrom: action?.val?.contractRegistrationDateFrom,
        contractRegistrationDateTo: action?.val?.contractRegistrationDateTo,
    };

    try {
        const response = yield ContractService.searchContractForList(payload);
        if (!response?.data?._embedded?.contracts) {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 499 } });
        }

        yield put({ type: SET_CONTRACT_LIST, val: response?.data?._embedded?.contracts });
        yield put({ type: SET_CONTRACT_LIST_LINKS, val: response?.data?._links });
        yield put({ type: SET_CONTRACT_LIST_PAGEINFO, val: response?.data?.page });
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: error?.response?.status } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

export function* getProjectProperties(actionPayload) {
    yield put({ type: SET_LOADING, val: true });

    try {
        const queryParams = {
            prtId: actionPayload?.val,
        };

        const response = yield ContractService.getProjectProperties(queryParams);
        if (response?.status === 200) {
            yield put({ type: SET_PROJECT_PROPERTIES, val: response?.data[0] });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* getDiaries(actionPayload) {
    console.log('actionPayload', actionPayload);
    console.log(actionPayload?.val);
    yield put({ type: SET_LOADING, val: true });

    try {
        // const getPartnerData = yield select((state: RootState) => state?.contract?.partnerModel);
        // const getContractNumber = yield select((state: RootState) => state?.contract?.contractNumber);

        const response = yield ContractService.getDiaries(actionPayload?.val?.partnerId, actionPayload?.val?.contractNumber);
        if (response?.status === 200) {
            yield put({ type: SET_DIARIES, val: response?.data });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

export function* sendMail() {
    yield put({ type: SET_LOADING, val: true });
    const getPartnerData = yield select((state: RootState) => state?.contract?.partnerModel);
    const getContractNumber = yield select((state: RootState) => state?.contract?.contractNumber);
    try {
        const payload = {
            partnerId: getPartnerData?.partner,
            contractNumber: getContractNumber,
        };
        const response = yield ContractService.sendMail(payload);
        if (response?.status === 200) {
            return;
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* sendSms() {
    yield put({ type: SET_LOADING, val: true });
    const getPartnerData = yield select((state: RootState) => state?.contract?.partnerModel);
    const contractNumber = yield select((state: RootState) => state?.contract?.contractNumber);
    const phoneNumber = yield select((state: RootState) => state?.contract?.customerModel?.phoneNumber) ?? '"phone number"';
    const payload = {
        phoneNumber: phoneNumber,
    };
    try {
        const response = yield ContractService.sendSmsCode(payload, getPartnerData?.partner, contractNumber);
        if (response?.status === 200) {
            yield put({ type: SET_SMS_ID, val: response?.data?.smsId });
            // Sending sms successfully doesn't trigger notification
            return;
        } else {
            // Else does
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* verifySmsCode() {
    yield put({ type: SET_LOADING, val: true });
    const smsId = yield select((state: RootState) => state?.contract?.smsId);
    const paperlessCode = yield select((state: RootState) => (state?.form?.paperless as Record<'values', Record<'code', string>>)?.values?.code);

    const payload: smsAuthType = {
        smsId: smsId,
        smsCode: paperlessCode,
    };
    try {
        const response = yield ContractService.verifySmsCode(payload);
        if (response.status === 200) {
            yield put({ type: SET_CONTRACT_SAVED, val: true });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}

/**
 * Save new contract
 */
export function* saveNewContract() {
    yield put({ type: SET_LOADING, val: true });
    const getModels = yield select((state: RootState) => state?.contract);
    const getForm = yield select((state: RootState) => state?.form?.partnerForm?.values);
    // getModels?.bankAccountModel?.iban.toUpperCase();
    console.log('IBAN:', getModels?.bankAccountModel?.iban.toUpperCase());
    const payload = {
        contract: {
            taxIdentificationNumber: getModels?.customerModel?.vatNumber,
            articleType: getModels?.productModel?.model,
            articlePrice: getModels?.productModel?.price,
            brandId: getModels?.productModel?.brand,
            city: getModels?.customerModel?.city,
            comment: getModels?.productModel?.comment,
            countryId: getModels?.customerModel?.country,
            email: getModels?.customerModel?.email,
            employeeId: getModels?.partnerModel?.employee,
            employeeName: getForm?.newEmployee,
            groupId: getModels?.productModel?.group,
            languageId: getModels?.customerModel?.language,
            lastname: getModels?.customerModel?.customerName,
            partnerId: getModels?.partnerModel?.partner,
            phoneNumber: getModels?.customerModel?.phoneNumber,
            postcode: getModels?.customerModel?.postCode,
            receiptNumber: getModels?.partnerModel?.receiptNumber,
            salesDate: dayjs(new Date(getModels?.partnerModel?.sellingDate)).format('YYYY-MM-DD'),
            shipDate: dayjs(new Date(getModels?.partnerModel?.deliveryDate)).format('YYYY-MM-DD'),
            serialNumber: getModels?.productModel?.serial,
            shopId: getModels?.partnerModel?.shop,
            status: 'A',
            street: getModels?.customerModel?.street,
            streetNumber: getModels?.customerModel?.houseNumber,
            warrantySalesDate: dayjs(new Date(getModels?.partnerModel?.deliveryDate)).format('YYYY-MM-DD'),
            warrantySalesPrice: getModels?.productModel?.warrantySellingPrice,
            warrantyTypeId: getModels?.productModel?.warranty,
        },
        account: {
            languageId: getModels?.accountHolderModel?.languageAh,
            countryId: getModels?.accountHolderModel?.countryAh,
            customerName: getModels?.accountHolderModel?.customerNameAh,
            city: getModels?.accountHolderModel?.cityAh,
            postcode: getModels?.accountHolderModel?.postCodeAh,
            street: getModels?.accountHolderModel?.streetAh,
            streetNumber: getModels?.accountHolderModel?.houseNumberAh,
            phoneNumber: getModels?.accountHolderModel?.phoneNumberAh,
            email: getModels?.accountHolderModel?.emailAh,
            ibanNumber: getModels?.bankAccountModel?.iban.toUpperCase(),
            bankCode: getModels?.bankAccountModel?.iban?.slice(4, 7),
            bankCountryId: getModels?.bankAccountModel?.bankCountry,
            bankCity: getModels?.bankAccountModel?.bankCity,
            bankPostcode: getModels?.bankAccountModel?.bankPostCode,
            bankStreet: getModels?.bankAccountModel?.bankStreet,
            bankStreetNumber: getModels?.bankAccountModel?.bankStreetNumber,
        },
    };

    try {
        const response = yield ContractService.saveContract(payload);

        if (response.status === 200) {
            yield put({ type: SET_CONTRACT_NUMBER, val: response?.data?.contractNumber ? response?.data?.contractNumber : response?.data?.contract?.contractNumber });
        } else {
            if (response.status === 400 && response?.data?.message.includes('email_is_black_listed')) {
                yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 413 } });
            } else if (response.status === 400 && response?.data?.message.includes('wrong_email_syntax')) {
                yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 414 } });
            } else if (response.status === 400 && response?.data?.message.includes('host_does_not_exist')) {
                yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 415 } });
            } else if (response.status === 400 && response?.data?.message.includes('invalid_mx_record')) {
                yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 416 } });
            } else {
                yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
            }
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: 500 } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
