export const GeneralErrors = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    MISSINGFIELD: 406,
    PRECONDITIONAL_FAILED: 412,
    NOT_MATCH: 499,
    INTERNAL_SERVER_ERROR: 500,
    NO_CONNECTION: 'undefined',
    email_is_black_listed: 413,
    wrong_email_syntax: 414,
    host_does_not_exist: 415,
    invalid_mx_record: 416,
};
