/* eslint-disable camelcase */
import common_en from './en/common.json';
import common_fr from './fr/common.json';
import contract_fr from './fr/contract.json';
import common_nl from './nl/common.json';
import contract_nl from './nl/contract.json';

export default {
    en: {
        common: common_en,
    },
    fr: {
        common: common_fr,
        contract: contract_fr,
    },
    nl: {
        common: common_nl,
        contract: contract_nl,
    },
};
