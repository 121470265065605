export default {
    buttonVariant: 'text', // [text, contained, outlined]
    selectVariant: 'filled', // [filled, outlined, standard]
    fieldVariant: 'outlined', // [filled, outlined, standard]

    // Color themes
    primaryColor: '#FF7900',
    secondaryColor: '#DDDDDD',

    // ButtonThemes
    buttonPrimary: {
        // TODO Button Primary
        backgroundColor: '#000000',
        color: 'white',
    },
    buttonSecondary: {
        // TODO Button Secondary
        backgroundColor: '#ffffff',
        color: 'black',
    },
    buttonTertiary: {
        // TODO Button Tertiary
        backgroundColor: 'transparent',
        color: 'black',
    },
};
