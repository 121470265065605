// import { reduxForm } from 'redux-form';
import { LoadingScreen } from '@MyComponents';
import { handleGeneralError } from '@src/api/errorHandling/ErrorHandling';
import { GeneralErrors } from '@src/api/models/Error';
import RootState from '@src/interfaces/RootState';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setNotification } from '../ui/Notifications';
import ResponsiveAppBar from './navigation/index';

import { ReactNotifications } from 'react-notifications-component';
import Footer from './footer';
import Header from './header';
const Layout = ({ children }) => {
    /* STATES */

    /* VARIABLES */
    const {
        location: { pathname },
    } = useHistory();
    const history = useHistory();
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();

    const errObject = useSelector((state: RootState) => state.error?.errorObject);

    /* FUNCTIONS */
    const errorHandling = (errObject) => {
        if (errObject?.errorCode === 401 && errObject?.dictionaryObject !== 'verify_errors') {
            sessionStorage.removeItem('token');
            history.replace('/');
        }
        let appearance = 'warning';
        let title = 'test';
        let target = 'test';

        switch (errObject?.errorCode) {
            case 200:
                appearance = 'success';
                title = t('error_handling.success');
                break;
            case 299:
                appearance = 'success';
                title = t('error_handling.iban');
                break;
            case 598:
                appearance = 'danger';
                title = t('error_handling.nobank');
                break;
            case 599:
                appearance = 'danger';
                title = t('error_handling.noiban');
                break;
            case 401:
                appearance = 'warning';
                title = t('error_handling.warning');
                break;
            case 499:
                appearance = 'warning';
                title = t('error_handling.warning');
                break;
            default:
                appearance = 'danger';
                title = t('error_handling.error');
        }
        target = t(`${errObject?.dictionaryObject}.${handleGeneralError(GeneralErrors, errObject?.errorCode)}`);
        if (target) {
            console.log('ERROR_NOTIFICATION:', title, target, appearance);
            setNotification(title, target, appearance);
            dispatchStore({ type: 'SET_ERROR_OBJECT', val: null });
        } else {
            console.log(title, ' ', appearance);
            setNotification(title, ' ', appearance);
            dispatchStore({ type: 'SET_ERROR_OBJECT', val: null });
        }
    };

    /* USEEFFECTS */
    useEffect(() => {
        if (errObject !== null) {
            errorHandling(errObject);
        }
    }, [errObject]);
    return (
        <div className="layout">
            <ReactNotifications />
            <Header />
            {/* <ResponsiveAppBar pathname={pathname} /> */}
            {/* <Container maxWidth="lg" sx={{ padding: '1rem' }}> */}

            <LoadingScreen />
            {children}
            {/* </Container> */}
            <Footer />
        </div>
    );
};

export default Layout;

// export default reduxForm({
//     form: '{{form}}',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     touchOnBlur: false
// })(index)
