import { Customer } from '../interfaces/Customer';
import store from '@src/redux/store';
import { SET_CUSTOMER } from '@src/redux/contract/actions';
import RootState, { SelectListItem } from '@src/interfaces/RootState';
import { change } from 'redux-form';
export class CustomerModel implements Customer {
    customerName: string;
    vatNumber: string;
    country: string;
    language: string;
    postCode: string;
    city: string;
    street: string;
    houseNumber: string;
    phoneNumber: number;
    email: string;
    boxNumber: string;
    countryItem: SelectListItem;
    languageItem: SelectListItem;

    constructor(customer: Customer) {
        this.customerName = customer.customerName;
        this.vatNumber = customer.vatNumber;
        this.country = customer.country;
        this.language = customer.language;
        this.postCode = customer.postCode;
        this.city = customer.city;
        this.street = customer.street;
        this.houseNumber = customer.houseNumber;
        this.phoneNumber = customer.phoneNumber;
        this.email = customer.email;
        this.boxNumber = customer.boxNumber;
        this.countryItem = store.getState().unit.countryList.find((item: SelectListItem) => item.value === customer.country);
        this.languageItem = store.getState().unit.languageList.find((item: SelectListItem) => item.value === customer.language);
    }
    public summarize() {
        return {
            customer_name: this.customerName,
            vat_number: this.vatNumber,
            country: this.countryItem.name,
            post_code: this.postCode,
            city: this.city,
            street: this.street,
            house_number: this.houseNumber,
            phone_number: this.phoneNumber,
            email: this.email,
        };
    }
    public static populateForm() {
        const customerModel = store.getState().contract.customerModel;
        if (customerModel) {
            Object.entries(customerModel).forEach((element) => {
                store.dispatch(change('customerForm', element[0], element[1]));
            });
        }
    }
    public static populateAccountHolderForm() {
        const customerModel = store.getState().contract.customerModel;
        if (customerModel) {
            Object.entries(customerModel).forEach((element) => {
                store.dispatch(change('accountHolderForm', `${element[0]}Ah`, element[1]));
            });
        }
    }
    public static storeCustomer(instance) {
        store.dispatch({ type: SET_CUSTOMER, val: instance });
    }
}
