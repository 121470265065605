import { Box } from '@mui/material';
import RootState from '@src/interfaces/RootState';
import * as SelectInterfaces from '@src/interfaces/SelectInterface';
import { useEffect } from 'react';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, change } from 'redux-form';
import { required } from 'redux-form-validators';
import renderSelectField from '../form/Select';

export const languageList: SelectInterfaces.SelectList = [
    // { name: 'EN', value: 'en', label: 'EN' },
    { name: 'NL', value: 'nl', label: 'NL' },
    { name: 'FR', value: 'fr', label: 'FR' },
];

const SelectLanguage = () => {
    const currentLng = languageList.find((f) => f.value === localStorage.getItem('language'));

    const { i18n } = useTranslation('common');
    const { t } = useTranslation('common');
    const storedLng = useSelector((state: RootState) => state?.form?.languageForm?.values?.selectedLanguage);
    const dispatchStore = useDispatch();
    useEffect(() => {
        if (storedLng !== undefined) {
            i18n.changeLanguage(storedLng);
            localStorage.setItem('language', storedLng);
        }
    }, [storedLng]);

    useEffect(() => {
        console.log('currentLng', currentLng);
        if (currentLng == undefined) localStorage.setItem('language', 'nl');
        dispatchStore(change('loginForm', 'selectedLanguage', currentLng?.value ? currentLng?.value : 'nl'));
        dispatchStore(change('languageForm', 'selectedLanguage', currentLng?.value ? currentLng?.value : 'nl'));
    }, []);

    return (
        <Box className="form__form-group" sx={{ marginTop: '16px', marginBottom: '8px' }}>
            <div className="">
                <div id="languageSelector" style={{ backgroundColor: '#fff', maxHeight: '50px' }}>
                    <Field
                        id="selectedLanguage"
                        name="selectedLanguage"
                        component={renderSelectField}
                        options={languageList}
                        type="hidden"
                        // placeholder={t('login.language')}
                        value="selectedLanguage"
                        defaultValue={currentLng?.label ?? 'nl'}
                        validate={[required({ msg: t('validations.required') })]}
                        isDisabled={true}
                    ></Field>
                </div>
            </div>
        </Box>
    );
};

export default SelectLanguage;
