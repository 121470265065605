import { Box, Button, CardMedia, Container, MenuItem, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuthContext } from '@src/contexts/auth/authContext';
import { RESET, SET_BACKDROP } from '@src/redux/ui/actions';
import { useHistory } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import LanguageSelector from '@src/components/ui/LanguageSelector';
import { reduxForm } from 'redux-form';

const Header = (props) => {
    let brand;
    const lang = localStorage.getItem('language');
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    /* VARIABLES */
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    const history = useHistory();

    const {
        authState: { isAuthenticated },
    } = useContext<any>(AuthContext);
    const { authDispatch } = useContext<any>(AuthContext);

    /* FUNCTIONS */
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
        dispatchStore({ type: SET_BACKDROP, val: true });
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);

        dispatchStore({ type: SET_BACKDROP, val: false });
    };

    const handleLogout = () => {
        // TODO Handling logout from SAGA -> session clear, reset redux. Useful when login expires
        sessionStorage.clear();
        dispatchStore({ type: RESET });
        authDispatch({ type: 'SIGNED_OUT' });
        history.push('/');
    };

    /* USEEFFECTS */
    // Every render check if cookie exists, if so, run dispatch
    useEffect(() => {
        if (sessionStorage.getItem('token') !== null) authDispatch({ type: 'SIGNIN_SUCCESS' });
    }, []);
    return (
        <Box
            sx={{
                height: '70px',
                maxHeight: '70px',
                top: '0',
                width: '100%',
                flex: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                backgroundColor: '#000000',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center' } }}>
                <div style={{ width: '100%', height: '70px', padding: '8px', marginLeft: '25px' }}>
                    <Link to="/dashboard">
                        <img style={{ height: '100%' }} src="/images/orange_logo.svg" alt="" />
                    </Link>
                </div>
                <div style={{ width: '150px', marginRight: isAuthenticated ? '0px' : '165px' }}>
                    <form action="">
                        <LanguageSelector />
                    </form>
                </div>
                {isAuthenticated && (
                    <div className="desktop-menu">
                        <Button type={'button'} className="logout-button" onClick={handleLogout}>
                            <div className="navigation__link navigation__link-desktop">
                                <MenuItem className="logout-button__item" sx={{ textTransform: 'none' }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <LogoutIcon />
                                        <Typography sx={{ marginLeft: '10px', fontWeight: '500' }} textAlign="center">
                                            {t('navigation.logout')}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            </div>
                        </Button>
                    </div>
                )}
            </Box>

            {/* <Box sx={{ color: '#acb4bf' }}>Copyright &#169; SPB Benelux</Box> */}
        </Box>
    );
};

export default reduxForm({
    form: 'languageForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    touchOnBlur: true,
})(Header);
