// import { Claim } from '@src/interfaces/RootState';
// import { SET_CLAIM_HISTORY, SET_ACTIVE_CLAIM, SET_CUSTOMER, RESET } from './actions';

import { Contract } from '@src/interfaces/RootState';
import {
    RESET_CONTRACT_STORE,
    SET_ACCOUNT_HOLDER,
    SET_BANK_ACCOUNT,
    SET_CONTRACT_NUMBER,
    SET_CONTRACT_SAVED,
    SET_CUSTOMER,
    SET_CONTRACT_DATA,
    SET_PARTNER,
    SET_PRODUCT,
    SET_PROJECT_PROPERTIES,
    SET_DIARIES,
    SET_SMS_ID,
    SET_CONTRACT_LIST,
    SET_CONTRACT_LIST_LINKS,
    SET_CONTRACT_LIST_PAGEINFO,
} from './actions';

const initialState: Contract = {
    customerModel: null,
    accountHolderModel: null,
    bankAccountModel: null,
    partnerModel: null,
    productModel: null,
    contractNumber: null,
    contractSaved: false,
    contractList: null,
    smsId: null,
    projectProperties: null,
    contractListLinks: null,
    contractListPageInfo: null,
    contractData: null,
    diaries: null,
};
/**
 * Claim related data
 */
export default function contractReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DIARIES:
            return {
                ...state,
                diaries: action.val,
            };
        case SET_CONTRACT_DATA:
            return {
                ...state,
                contractData: action.val,
            };
        case SET_PARTNER:
            return {
                ...state,
                partnerModel: action.val,
            };
        case SET_PRODUCT:
            return {
                ...state,
                productModel: action.val,
            };
        case SET_BANK_ACCOUNT:
            return {
                ...state,
                bankAccountModel: action.val,
            };
        case SET_ACCOUNT_HOLDER:
            return {
                ...state,
                accountHolderModel: action.val,
            };
        case SET_CUSTOMER:
            return {
                ...state,
                customerModel: action.val,
            };
        case SET_CONTRACT_NUMBER:
            return {
                ...state,
                contractNumber: action.val,
            };
        case SET_CONTRACT_SAVED:
            return {
                ...state,
                contractSaved: true,
            };
        case SET_SMS_ID:
            return {
                ...state,
                smsId: action.val,
            };
        case SET_PROJECT_PROPERTIES:
            return {
                ...state,
                projectProperties: action.val,
            };
        case SET_CONTRACT_LIST:
            return {
                ...state,
                contractList: action.val,
            };
        case SET_CONTRACT_LIST_LINKS:
            return {
                ...state,
                contractListLinks: action.val,
            };
        case SET_CONTRACT_LIST_PAGEINFO:
            return {
                ...state,
                contractListPageInfo: action.val,
            };
        case RESET_CONTRACT_STORE:
            return initialState;
        default:
            return state;
    }
}
