import {
    saveNewContract,
    SAVE_NEW_CONTRACT,
    verifySmsCode,
    VERIFY_SMS_CODE,
    SEND_MAIL,
    sendMail,
    sendSms,
    SEND_SMS,
    GET_PROJECT_PROPERTIES,
    getProjectProperties,
    SEARCH_CONTRACT,
    searchContract,
    SEARCH_CONTRACT_FOR_LIST,
    searchContractForList,
    GET_CONTRACT,
    getContract,
    SAVE_MODIFIED_CONTRACT,
    saveModifiedContract,
    GET_DIARIES,
    getDiaries,
} from './actions';
import { takeLatest } from 'redux-saga/effects';

export default [
    takeLatest(SEND_MAIL, sendMail),
    takeLatest(SAVE_NEW_CONTRACT, saveNewContract),
    takeLatest(SAVE_MODIFIED_CONTRACT, saveModifiedContract),
    takeLatest(VERIFY_SMS_CODE, verifySmsCode),
    takeLatest(SEND_SMS, sendSms),
    takeLatest(SEARCH_CONTRACT, searchContract),
    takeLatest(SEARCH_CONTRACT_FOR_LIST, searchContractForList),
    takeLatest(GET_PROJECT_PROPERTIES, getProjectProperties),
    takeLatest(GET_CONTRACT, getContract),
    takeLatest(GET_DIARIES, getDiaries),
];
