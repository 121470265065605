import axiosInstance from '../http/axiosSetup';
import store from '@src/redux/store';
export default class UnitService {
    static getDevice = (deviceId) => {
        return axiosInstance
            .get(`devices/${deviceId}`)
            .then((res) => {
                console.log('store getstate', store.getState());
                res.data.brand = res.data.brand.id;
                res.data.group = res.data.group.id;
                res.data.model = res.data.model.name;
                res.data.price = res.data.purchasePrice;
                res.data.serial = res.data.serialNumber;
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static captchaGenerate = (queryParams) =>
        axiosInstance
            .get(`/captcha/generate`, { params: queryParams })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static warrantyList = (queryParams) =>
        axiosInstance
            .get(`/common-data-managements/warranty-types`, { params: queryParams })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static productBrandList = (queryParams) =>
        axiosInstance
            .get(`/common-data-managements/product-brands`, {
                params: queryParams,
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static productGroupList = (queryParams) =>
        axiosInstance
            .get(`/common-data-managements/product-groups`, {
                params: queryParams,
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    // static employeeList = (body) =>
    //     axiosInstance
    //         .post(`employee/list`, body)
    //         .then((res) => {
    //             return res;
    //         })
    //         .catch((err) => {
    //             return err.response;
    //         });
    static employeeList = (body) => {
        return axiosInstance
            .get(`/shop-managements/shops/${body.partnerId}/${body.shopId}/employees`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static shopDetails = (payload) =>
        axiosInstance
            .get(`/shop-managements/shops/${payload?.partnerId}/${payload?.shopId}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static shopList = (payload) =>
        axiosInstance
            .get(`/shop-managements/shops?partnerIds=${payload?.partnerId}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static bankList = (body) =>
        axiosInstance
            .post('/bank/list', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static bankListNew = (bankCode, countryId) =>
        axiosInstance
            .get(`/common-data-managements/bank/${bankCode}/${countryId}`)
            .then((res) => {
                console.log('UnitService ~ .then ~ res:', res);
                return res;
            })
            .catch((err) => {
                console.log('UnitService ~ err:', err);

                return err.response;
            });
    // static bankCountryList = () =>
    //     axiosInstance
    //         .get('/bank/country/list')
    //         .then((res) => {
    //             return res;
    //         })
    //         .catch((err) => {
    //             return err.response;
    //         });
    static bankCountryListNew = () =>
        axiosInstance
            .get('/common-data-managements/bank-countries')
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static countryList = () =>
        axiosInstance
            .get('/common-data-managements/countries')
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static languageList = () =>
        axiosInstance
            .get('/common-data-managements/languages')
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static partnerList = () =>
        axiosInstance
            .get(`partner-managements/partners`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
}
